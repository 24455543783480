<template>
	<div class="messages-box ">
		<h1 class="error-msg flex-center">{{$Vmessages("Youdon’t_4a9b")}}</h1>
		
		<ul class="messages-pc-ul md:hidden xm:hidden ">
			<div class="ul-head">
				<span>Time</span>
				<span>Messages</span>
				<span>Next Step</span>
			</div>
			<li v-for="item in 5">
				<div class="li-left">
					<span>2024-1-15 10:52:10</span>
				</div>
				<div class="li-center">
					<span>Congratulations! You are eligible to claim the Early Settlers Lv.1!   </span>
				</div>
				<div class="li-right">
					<messagesBtn></messagesBtn>
				</div>
			</li>
		</ul>

		<ul class="messages-h5-ul md:block xm:block  xl:hidden l:hidden ">
			<li v-for="item in 5">
				<div class="li-1 flex-between">
					<dl>
						<dt>Time</dt>
						<dd>2024-1-15 10:52:10</dd>
					</dl>
					<dl>
						<dt>Next Step</dt>
						<dd>
							<messagesBtn></messagesBtn> 
						</dd>
					</dl>
				</div>
				<div class="li-2">
					<span>Messages</span>
				</div>
				<div class="li-3"><span>Congratulations! You are eligible to claim the Early Settlers Lv.1!   </span>
				</div>
			</li>
		</ul>

	</div>
</template>

<script>
	import messagesBtn from './messagesBtn.vue'
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VmessagesMessages from "./messages.messages.json";
  const $defaultObject = {
		name: 'Name',
		components: { messagesBtn },
		data() {
			return {

			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {

		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $Vmessages(key,origin){
      const messages = $VmessagesMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $Vmessages(key,origin){
      const messages = $VmessagesMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
	.messages-box {
		width: 1440px;
		min-height: 560px;
		border-radius: 20px;
		border: 1px solid rgba(255, 255, 255, 0.2);

		.error-msg {
			display: inline-block;
			width: 100%;
			height: 560px;
			line-height: 560px;
			
			font-size: 24px;
			font-weight: 700;
			letter-spacing: 0em;
			text-align: center;
			color: rgba(255, 255, 255, 0.6);
		}

		.messages-pc-ul {
			width: 100%;
			padding: 60px;

			.ul-head {
				display: grid;
				grid-template-columns: 1fr 3fr 1fr;


				span {
					display: inline-block;
					
					font-size: 32px;
					font-weight: 700;
					line-height: 23px;
					letter-spacing: 0em;
					text-align: left;
					color: rgba(255, 255, 255, 1);

				}

				span:nth-of-type(3) {
					text-align: center;
				}
			}

			li {
				display: grid;
				grid-template-columns: 1fr 3fr 1fr;
				padding: 30px 0;
				align-items: center;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);

				.li-left {
					
					font-size: 20px;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: left;
					color: rgba(255, 255, 255, 0.6);


				}

				.li-center {
					
					font-size: 20px;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: left;
					color: rgba(255, 255, 255, 0.6);

				}

				.li-right {
					text-align: center;
				}
			}
		}

		@screen md {
			width: 95%;
			min-height: 180px;

			.error-msg {
				font-size: 12px;
				height: 180px;
				line-height: 180px;
			}

			.messages-h5-ul {
				li {
					padding: 20px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.2);

					.li-1 {
						dt {
							
							font-size: 14px;
							font-weight: 700;
							line-height: 11px;
							letter-spacing: 0em;
							text-align: left;
							color: rgba(255, 255, 255, 1);
						}

						dd {
							padding-top: 16px;
							
							font-size: 12px;
							font-weight: 500;
							line-height: 24px;
							letter-spacing: 0em;
							text-align: left;
							color: rgba(255, 255, 255, 0.6);

						}


					}
					.li-2{
						
						font-size: 14px;
						font-weight: 700;
						line-height: 11px;
						letter-spacing: 0em;
						text-align: left;
						color: rgba(255, 255, 255, 1);
					}
					.li-3{
						padding-top: 16px;
						
						font-size: 12px;
						font-weight: 500;
						line-height: 24px;
						letter-spacing: 0em;
						text-align: left;
						color: rgba(255, 255, 255, 0.6);
					}
				}
			}



		}

	}
</style>
<style>
	.ant-pagination {
		.ant-pagination-item {
			a {
				color: #fff;
			}

		}
	}
</style>