<template>
	<div class="btn-box" :class="{'dis':status == 3}">
		<a-button type="primary" @click="addRewardLog" :loading="isLoading">
			{{statusOb[status]}}
		</a-button>
	</div>
</template>

<script>
	import { rewardLog, addRewardLog, checkRewardStatus, checkStatus, RewardStatusReq } from '@/utils/twitter.api.js';
	import { enable } from "@/web3utils/base/index.js";
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		data() {
			return {
				isLoading: false,
				status: 0,
				GzStatus: 0,
				statusOb: {
					0: 'Go to Bridge',
					1: 'Claim',
					2: 'Claiming',
					3: 'Claim',
				},
			}
		},
		computed: {
			...mapState(['address']),
		},
		mounted() {
			this.checkStatus();
		},
		methods: {
			async checkStatus() {
				const { success, data: { status }, code } = await checkStatus({
					addr: this.address,
				})
				this.GzStatus = status || 0;
				if (status <= 0) {
					// this.statusOb[0] = 'Connect wallet'
				} else if (status >= 3) {
					this.statusOb[0] = 'Claim'
				}
			},
			async addRewardLog() {
				this.$emit("confirm");
				return 
				
				this.goLogin();
				
				if (this.status == 1) {
					this.isLoading = true;
					setTimeout(async () => {
						const { data: { status } } = await addRewardLog({
							addr: this.address,
						})
						this.isLoading = false;
						this.rewardLog();
						this.checkRewardStatus();
						if (status == 1) {
							this.isFaucet = true;
						}
					}, 500)
				}

				if (this.GzStatus < 3) {
					return this.$router.push('/Setting')
				}
			

				if (this.status == 0 && (this.reward_count < this.rewardInfo.reward_num)) {
					await this.checkRewardStatus();
					this.isTime = true;
				}

			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					this.$message('Wallet not logged in')
					return;
				}
			},


		}
	}
</script>

<style lang="scss" scoped>
	.btn-box {
		.ant-btn-primary {
			height: unset;
	
			line-height: unset;
			padding: 8px 20px;
			border-radius: 100px;
	
			font-size: 18px;
			color: #fff;
			font-weight: 400;
			background: linear-gradient(to right, #203dfc, #33c4f2);
			border: none;
	
			@screen md {
				height: unset;
				line-height: unset;
				padding: 0px 15px;
				font-size: 12px;
			}
	
		}
	}
</style>