var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"messages-box"},[_c('h1',{staticClass:"error-msg flex-center"},[_vm._v(_vm._s(_vm.$Vmessages("Youdon’t_4a9b")))]),_c('ul',{staticClass:"messages-pc-ul md:hidden xm:hidden"},[_vm._m(0),_vm._l((5),function(item){return _c('li',[_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"li-right"},[_c('messagesBtn')],1)])})],2),_c('ul',{staticClass:"messages-h5-ul md:block xm:block xl:hidden l:hidden"},_vm._l((5),function(item){return _c('li',[_c('div',{staticClass:"li-1 flex-between"},[_vm._m(3,true),_c('dl',[_c('dt',[_vm._v("Next Step")]),_c('dd',[_c('messagesBtn')],1)])]),_vm._m(4,true),_vm._m(5,true)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ul-head"},[_c('span',[_vm._v("Time")]),_c('span',[_vm._v("Messages")]),_c('span',[_vm._v("Next Step")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"li-left"},[_c('span',[_vm._v("2024-1-15 10:52:10")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"li-center"},[_c('span',[_vm._v("Congratulations! You are eligible to claim the Early Settlers Lv.1!   ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dl',[_c('dt',[_vm._v("Time")]),_c('dd',[_vm._v("2024-1-15 10:52:10")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"li-2"},[_c('span',[_vm._v("Messages")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"li-3"},[_c('span',[_vm._v("Congratulations! You are eligible to claim the Early Settlers Lv.1!   ")])])
}]

export { render, staticRenderFns }